<template>
  <header class="simple-header ">
    <div class="header-left header-pub">
      <van-icon
        name="arrow-left"
        size="0.6rem"
        v-if="isback"
        @click="goBack"
      />
    </div>
    <div
      class="simple-header-name"
      v-if="!seachShow"
    >{{ name }}</div>
    <div
      class="simple-header-seach"
      v-if="seachShow"
    >
      <van-search
        placeholder="请输入手机号搜索"
        shape="round"
        v-model="seachValue"
        focus
        @search="onSearch"
      />
    </div>
    <div class="heder-right header-pub">
      <slot name="seachRight">
        <!-- <span @click="onRight"> {{rName}}</span> -->
      </slot>
    </div>
  </header>
</template>
<script>
// 头部导航栏组件
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    isback: {
      type: Boolean,
      default: true,
    },
    rName: {
      type: String,
      default: "",
    },
    rShow: {
      type: Boolean,
      default: false,
    },
    seachShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      seachValue: "",
    };
  },
  created() {},
  mounted() {},
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    onRight() {
      this.$emit("onRight");
    },
    onSearch(val) {
      this.$emit("onSearch", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.simple-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 44px;
  line-height: 44px;
  padding: 0;
  box-sizing: border-box;
  color: #252525;
  background: #fff;
  &::after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    border: 1px solid #ebedf0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  .header-pub {
    position: absolute;
    top: 0;
    bottom: 0;

    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 0 16px;
    font-size: 14px;
    cursor: pointer;
  }
  .header-left {
    left: 0;
  }
  .heder-right {
    right: 0;
    font-size: 12px;
    color: #767676;
  }
  .simple-header-name {
    font-size: 16px;
    width: 100%;
    text-align: center;
  }
  .simple-header-seach {
    width: 80%;
    padding-left: 40px;
  }
  .van-search {
    padding-bottom: 0;
    padding-top: 6px;
  }
}
</style>
